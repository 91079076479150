:root {
  --ck-color-focus-border: transparent;
  --ck-color-shadow-inner: transparent;
  --ck-inner-shadow: 0 var(--ck-color-shadow-inner) inset;
  --ck-border-radius: 0;
  --ck-spacing-standard: 0.875rem;
}

.ck-content {
  height: 550px;
  max-height: 550px;
}
